import Image from "next/image";
import Link from "next/link";
import React from "react";
import formatDate from "../../utils/dateFormatter";

const News_item = ({
  data,
  preslug,
  external,
  classes = "grid grid-cols-1 gap-[1.875rem] sm:grid-cols-2 md:grid-cols-3",
}) => {

  return (
    <>
      <div className={classes}>
        {data && data.map((item) => {
          const { id, featuredImage, title, excerpt, date, slug, externalLink } = item;
          const link = externalLink ? (externalLink.startsWith('http://') || externalLink.startsWith('https://') ? externalLink : `http://${externalLink}`) : ''

          return (
            <article key={id}>
              <div className="rounded-2xl overflow-hidden transition-shadow hover:shadow-lg">
                <figure className="group overflow-hidden h-[214px]">
                  {!external ? <Link href={`/${preslug}/${slug}`}>
                    <Image
                      width={370}
                      height={214}
                      src={featuredImage?.sourceUrl}
                      alt={title}
                      className="h-full w-full object-cover transition-transform duration-[1600ms] will-change-transform group-hover:scale-105 "
                    />
                  </Link> :
                    <a href={`${link}`} target="_blank">
                      <Image
                        width={370}
                        height={214}
                        src={featuredImage?.sourceUrl}
                        alt={title}
                        className="h-full w-full object-cover transition-transform duration-[1600ms] will-change-transform group-hover:scale-105 "
                      />
                    </a>


                  }
                </figure>

                {/* <!-- Body --> */}
                <div className="dark:border-jacarta-600 dark:bg-jacarta-900 border-jacarta-100 rounded-b-[1.25rem] border border-t-0 bg-white p-[10%]">


                  <h2 className="font-display line-clamp-2 text-jacarta-900 dark:hover:text-accent hover:text-accent mb-4 text-xl dark:text-white">
                    {!external ? <Link href={`/${preslug}/${slug}`}>{title}</Link> : <a href={link} target="_blank">{title}</a>}
                  </h2>
                  <p className="dark:text-jacarta-200 mb-8 h-[72px]">
                    <div className="line-clamp-3" dangerouslySetInnerHTML={{ __html: excerpt }} />
                  </p>

                  {/* <!-- Date / Time --> */}
                  <div className="text-jacarta-400 flex flex-wrap items-center space-x-2 text-sm">
                    <span>
                      <time>{formatDate(date)}</time>
                    </span>
                  </div>
                </div>
              </div>
            </article>
          );
        })}
      </div>
    </>
  );
};

export default News_item;
