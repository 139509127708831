import React, { useState } from 'react'
import { Fade } from 'react-reveal'
import News_item from '../../../components/blog/news_item';

function Press({data}) {
     const [dataItem, setdataItem] = useState(data?.slice(0, 3) || []);
  return (
     <div className="container relative py-12  md:py-[98px] lg:py-[118px] ">
          <Fade ssrReveal bottom delay={350} >
            <div className="text-center mb-16 max-w-full w-[700px] mx-auto">
           <h2 className="text-[38px] font-medium mb-4">
             What does the press say?
           </h2>
           <p className="text-[18px] dark:!text-jacarta-400">
           
Read the latest news, announcements, and press coverage about NetZylo's innovations in Web3, Al, and blockchain engagement.
           </p>
         </div>
         </Fade>
         
         <News_item data={dataItem}
              preslug='news'
              external={true}
            />
    </div>
  )
}

export default Press